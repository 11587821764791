<template>
  <div class="questionnairePage">
    <div v-if="questionnaireType===1">
      <div v-for="template in templateList"
        class="option-box"
        :key="template.id">
        <div class="padding-box">
          {{template.sort_index}}、{{template.title}}
        </div>
        <el-radio-group v-model="template.option_id"
          class="padding-group">
          <el-radio v-for="optic in template.optionList"
            :key="optic.id"
            :label="optic.id">{{optic.content}}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-else>
      <div>竹香学校教师年度考核业务素养评分细则</div>
      <hr>
      <div>
		<span>考核对象：{{$route.query.mname}}</span>
		<span style="margin-left: 80px">评分时间：{{$route.query.opertime}}</span>
	  </div>
      <el-table :data="templateList"
        border>
        <el-table-column label="领域"
          width="40px"
          prop="ly"></el-table-column>
        <el-table-column label="项目"
          width="120px"
          prop="xm"></el-table-column>
        <el-table-column label="评分细则"
          prop="xz"></el-table-column>
        <el-table-column label="评分说明"
          prop="sm"></el-table-column>
        <el-table-column label="分值"
          width="50px"
          align="center"
          prop="maxscore"></el-table-column>
        <el-table-column label="自评"
          width="80px"
          align="center">
          <template slot-scope="props">
            <el-input :disabled="type===1"
              :min="1"
              type="number"
              @change="thisMaxValue(props.row)"
              v-model="props.row.zscore"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="得分"
          width="80px"
          align="center">
          <template slot-scope="props">
            <el-input :disabled="type===0"
              :min="1"
              type="number"
              @change="thisMaxValue(props.row)"
              v-model="props.row.score"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="签名"
          width="50px"
          align="center"
          prop=""></el-table-column>
        <el-table-column label="部门"
          width="120px"
          align="center"
          prop="groupname"></el-table-column>
      </el-table>
    </div>
    <el-button type="primary"
      @click="answerSubmit"
      v-if="questionnaireStatus!==2"
      class="button-box">提 交
    </el-button>
  </div>
</template>

<script>
export default {
  name: "questionnairePage",
  data() {
    return {
      questionnaireType: Number(this.$route.query.questionnaireType), //问卷类型
      questionnaireStatus: Number(this.$route.query.questionnaireStatus), //问卷状态
      status: this.$route.query.status, //业务测评的类型
      questionnaireNdid: Number(this.$route.query.ndid),
      questionnaireAssessedId: Number(this.$route.query.managerId),
      type: Number(this.$route.query.type), //自评或他评
      templateList: [],
      currentScore: [],
      questionnaireId: "",
      qt_id: Number(this.$route.query.qt_id),
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
    };
  },
  created() {
    if (Number(this.$route.query.questionnaireId)) {
      this.questionnaireId = Number(this.$route.query.questionnaireId);
    }
    this.loadQuestionnaire();
  },
  methods: {
    loadQuestionnaire() {
      const that = this;
      let apiUrl = "/base/queryZpNdEvaluationDetail.do"; //业务自评
      let data = { ndid: this.questionnaireNdid };
      if (this.type === 1) {
        apiUrl = "/base/queryPingNdEvaluationDetail.do";
        data.assessed_id = this.questionnaireAssessedId; //业务他评
      }
      if (this.questionnaireType === 1) {
        apiUrl = "/base/PQueryQuestionDetail.do"; //教师
        data.qtid = this.questionnaireId;
      }
      this.$post(apiUrl, data)
        .then((res) => {
          //记录领域类型
          let columnTitles = [];
          res.data.forEach((item) => {
            //记录同一领域的第一条数据，并赋值rowspan
            if (columnTitles.indexOf(item.ly) === -1) {
              item.rowspan = 0;
              columnTitles.push(item.ly);
            }
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].ly === item.ly) {
                item.rowspan += 1;
              }
            }
            if (item.score) {
              that.currentScore.push(item.score);
            }
          });
          that.templateList = res.data;
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    thisMaxValue(row) {
      if (Number(row.zscore) > Number(row.maxscore)) {
        row.zscore = row.maxscore;
      } else if (Number(row.score) > Number(row.maxscore)) {
        row.score = row.maxscore;
      } else if (Number(row.zscore) < 0) {
        row.zscore = 0;
      } else if (Number(row.score) < 0) {
        row.score = 0;
      }
    },
    answerSubmit() {
      const that = this;
      this.$confirm("是否确认提交", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.questionnaireType === 1) {
            that.teacherAnswerSubmitConfirm();
          } else {
            that.businessAnswerSubmitConfirm();
          }
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    teacherAnswerSubmitConfirm() {
      const that = this;
      let answer = [];
      for (let i = 0; i < this.templateList.length; i++) {
        let newOption = {};
        if (this.templateList[i].option_id) {
          this.templateList[i].optionList.forEach((option) => {
            if (this.templateList[i].option_id === option.id) {
              newOption = option;
            }
          });
        } else {
          this.$message.error("选项不能为空");
          return;
        }
        let obj = {};
        obj.evaluation_mid = this.userInfo.userid;
        obj.assessed_mid = this.questionnaireAssessedId;
        obj.type = newOption.type;
        obj.qt_id = this.qt_id;
        obj.level = newOption.level;
        obj.topic_id = this.templateList[i].id;
        obj.option_id = newOption.id;
        obj.ndid = this.questionnaireNdid;
        answer.push(obj);
      }
      this.$postJson("/base/insertQuestionDetail.do", answer)
        .then((res) => {
          that.$message({
            type: "success",
            message: "操作成功!",
          });
          that.$router.go(-1);
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    businessAnswerSubmitConfirm() {
      const that = this;
      for (let i = 0; i < this.templateList.length; i++) {
        if (!this.templateList[i].zscore) {
          this.$message.error("自评分值未填写完全");
          return;
        } else if (this.type === 1 && !this.templateList[i].score) {
          this.$message.error("评价分值未填写完全");
          return;
        }
      }
      let answer = [];
      if (this.type === 1) {
        this.templateList.forEach((res, index) => {
          let obj = {};
          obj.id = res.id;
          this.currentScore.forEach((score, scoreIndex) => {
            if (
              this.templateList[index].score !==
                this.currentScore[scoreIndex] &&
              index === scoreIndex
            ) {
              obj.evaluation_id = this.userInfo.userid;
            }
          });
          obj.score = res.score;
          answer.push(obj);
        });
      } else {
        this.templateList.forEach((res) => {
          let obj = {};
          obj.ndid = res.ndid;
          obj.syid = res.id;
          obj.zscore = res.zscore;
          obj.groupid = res.groupid;
          answer.push(obj);
        });
      }
      this.$postJson("/base/insertNdEvaluationScore.do", {
        //自评、他评业务
        ndid: this.questionnaireNdid,
        assessed_id: this.questionnaireAssessedId,
        type: this.type,
        list: answer,
      })
        .then((res) => {
          that.$message({
            type: "success",
            message: "操作成功!",
          });
          that.$router.go(-1);
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.padding-box {
  padding: 20px 0px;
}

.button-box {
  margin-top: 30px;
  margin-left: 5%;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
.option-box {
  margin: 10px 0;
}
</style>
